import axios from 'axios';
import i18n from 'obiado/lib/i18n';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    state: {
        filters: {
            firstName: '',
            lastName: '',
            cardNumber: '',
            institutionId: null,
            classroom: '',
            parentFirstName: '',
            parentLastName: '',
            active: null
        },

        presencesFilters: {
            firstName: '',
            lastName: '',
            cardNumber: '',
            institutionId: null,
            classroom: '',
            parentFirstName: '',
            parentLastName: ''
        }
    },

    mutations: {
        setFirstNameFilter(state, firstName) {
            state.filters.firstName = firstName;
        },

        setLastNameFilter(state, lastName) {
            state.filters.lastName = lastName;
        },

        setCardNumberFilter(state, cardNumber) {
            state.filters.cardNumber = cardNumber;
        },

        setInstitutionIdFilter(state, institutionId) {
            state.filters.institutionId = institutionId;
        },

        setClassroomFilter(state, classroom) {
            state.filters.classroom = classroom;
        },

        setParentFirstNameFilter(state, parentFirstName) {
            state.filters.parentFirstName = parentFirstName;
        },

        setParentLastNameFilter(state, parentLastName) {
            state.filters.parentLastName = parentLastName;
        },

        setActiveFilter(state, active) {
            state.filters.active = active;
        },

        setPresencesFirstNameFilter(state, firstName) {
            state.presencesFilters.firstName = firstName;
        },

        setPresencesLastNameFilter(state, lastName) {
            state.presencesFilters.lastName = lastName;
        },

        setPresencesCardNumberFilter(state, cardNumber) {
            state.presencesFilters.cardNumber = cardNumber;
        },

        setPresencesInstitutionIdFilter(state, institutionId) {
            state.presencesFilters.institutionId = institutionId;
        },

        setPresencesClassroomFilter(state, classroom) {
            state.presencesFilters.classroom = classroom;
        },

        setPresencesParentFirstNameFilter(state, parentFirstName) {
            state.presencesFilters.parentFirstName = parentFirstName;
        },

        setPresencesParentLastNameFilter(state, parentLastName) {
            state.presencesFilters.parentLastName = parentLastName;
        }
    },

    actions: {
        async getMany({commit}, {filters, pageNumber, perPage}) {
            let q = {
                ...filters,
                page: pageNumber
            };

            if (perPage) {
                q.perPage = perPage;
            }

            const res = await axios.get(`${API_URL}/children`, {params: q});

            if (!res.data || typeof res.data.items !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getOne({commit}, childId) {
            const res = await axios.get(`${API_URL}/children/${childId}`);

            if (!res.data || typeof res.data.id !== 'number') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getCurrentParentChildren({commit}) {
            const res = await axios.get(`${API_URL}/parents/current/children`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async removeChild({commit}, childId) {
            const res = await axios.delete(`${API_URL}/children/${childId}`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async activate({commit}, childId) {
            const res = await axios.post(`${API_URL}/children/${childId}/activate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },

        async deactivate({commit}, childId) {
            const res = await axios.post(`${API_URL}/children/${childId}/deactivate`);

            if (res.status !== 200 || !res.data.status) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return true;
        },
    }
};

export default store;
