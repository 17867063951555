import i18n from 'obiado/lib/i18n';

const Transactions = () => import('./views/Transactions');
const ParentTransactionsModal = () => import('./views/ParentTransactionsModal');
const ParentTransactionEdit = () => import('./views/ParentTransactionEdit');
const CurrentParentTransactions = () => import('./views/CurrentParentTransactions');
const PayuPaid = () => import('./views/PayuPaid');

export default [
    {
        path: '/transactions',
        name: 'transactions',
        component: Transactions,

        meta: {
            label: i18n.t('Settlement')
        },

        children: [
            {
                path: ':parentId',
                name: 'transactions.parent',
                component: ParentTransactionsModal,

                meta: {
                    label: i18n.t('Parent transactions')
                },

                children: [
                    {
                        path: 'create',
                        name: 'transactions.parent.create',
                        component: ParentTransactionEdit,

                        meta: {
                            label: i18n.t('New transaction')
                        }
                    },

                    {
                        path: ':transactionId/edit',
                        name: 'transactions.parent.edit',
                        component: ParentTransactionEdit,

                        meta: {
                            label: i18n.t('Edit transaction')
                        }
                    }
                ]
            },
        ]
    },

    {
        path: '/billing',
        name: 'parent.billing',
        component: CurrentParentTransactions,

        meta: {
            label: i18n.t('Parent transactions')
        }
    },

    {
        path: 'payu/paid',
        name: 'payu.paid',
        component: PayuPaid
    }
];
