import i18n from 'obiado/lib/i18n';

import calendarRoutes from 'obiado/modules/calendar/routes';
import childrenRoutes from 'obiado/modules/children/routes';
import classroomsRoutes from 'obiado/modules/classrooms/routes';
import dietsRoutes from 'obiado/modules/diets/routes';
import institutionsRoutes from 'obiado/modules/institutions/routes';
import mealsMenusRoutes from 'obiado/modules/mealsMenus/routes';
import parentsRoutes from 'obiado/modules/parents/routes';
import profileRoutes from 'obiado/modules/profile/routes';
import reportsRoutes from 'obiado/modules/reports/routes';
import settingsRoutes from 'obiado/modules/settings/routes';
import terminalRoutes from 'obiado/modules/terminal/routes';
import transactionsRoutes from 'obiado/modules/transactions/routes';
import paymentsRoutes from 'obiado/modules/payments/routes';

const DashboardLayout = () => import('./components/DashboardLayout');
const Dashboard = () => import('./views/Dashboard');

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        name: 'home',
        component: DashboardLayout,

        meta: {
            label: i18n.t('Dashboard'),
            authorized: true
        },

        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard
            }
        ]
    }
];

routes[0].children = routes[0].children.concat(
    calendarRoutes,
    childrenRoutes,
    classroomsRoutes,
    dietsRoutes,
    institutionsRoutes,
    mealsMenusRoutes,
    parentsRoutes,
    profileRoutes,
    reportsRoutes,
    settingsRoutes,
    terminalRoutes,
    transactionsRoutes,
    paymentsRoutes
);

export default routes;
