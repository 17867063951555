import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Presences = () => import('./views/Presences');
const ChildPresences = () => import('./views/ChildPresences');
const ChildPresencesModal = () => import('./views/ChildPresencesModal');
const FreeDays = () => import('./views/FreeDays');
const ParentPresences = () => import('./views/ParentPresences');
const BulkPresences = () => import('./views/BulkPresences');

export default [
    {
        path: 'calendar',
        name: 'calendar',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Calendar')
        },

        children: [
            {
                path: 'presences',
                name: 'calendar.presences',
                component: Presences,

                meta: {
                    label: i18n.t('Presences')
                },

                children: [
                    {
                        path: ':childId',
                        name: 'calendar.presences.child',
                        component: ChildPresencesModal,

                        meta: {
                            label: i18n.t('Child presences')
                        }
                    }
                ]
            },

            {
                path: 'freeDays',
                name: 'calendar.freeDays',
                component: FreeDays,

                meta: {
                    label: i18n.t('Free days')
                }
            },

            {
                path: 'bulkPresences',
                name: 'calendar.bulkPresences',
                component: BulkPresences,

                meta: {
                    label: i18n.t('Bulk presences')
                }
            }
        ]
    },

    {
        path: '/presences',
        name: 'parent.presences',
        component: ParentPresences,

        meta: {
            label: i18n.t('Presences')
        },

        children: [
            {
                path: ':childId',
                name: 'parent.presences.child',
                component: ChildPresences,

                meta: {
                    label: i18n.t('Child presences')
                }
            }
        ]
    }
];
