import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Notifications from 'vue-notification';
import App from 'obiado/App';
import router from 'obiado/lib/router';
import axios from 'axios';
import filters from 'obiado/lib/filters';
import store from 'obiado/lib/store';
import i18n from 'obiado/lib/i18n';

axios.interceptors.response.use(response => {
    return response;
}, err => {
    console.dir(err);

    if (err.response.status === 401 && !err.config.url.includes('/auth/login')) {
        store.dispatch('auth/logout');

        Vue.prototype.$notify({
            type: 'error',
            title: i18n.t('Your session has expired.'),
            text: i18n.t('Please login again, to continue.')
        });

        return Promise.reject(err);
    } else if (err.response.status > 399) {
        if (err.response.data.message) {
            let message = i18n.t(err.response.data.message);

            if (err.response.data.errors) {
                message += '<ul>';

                for (let key in err.response.data.errors) {
                    message += `<li>${err.response.data.errors[key]}</li>`;
                }

                message += '</ul>';
            }

            Vue.prototype.$notify({
                type: 'error',
                text: message
            });
        } else {
            Vue.prototype.$notify({
                type: 'error',
                text: i18n.t('Error occured while processing your request.')
            });
        }
    }

    return Promise.reject(err);
});

Vue.use(BootstrapVue);
Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App></App>',
    components: {
        App
    }
});
