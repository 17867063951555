import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const Paid = () => import('./views/Paid');

export default [
    {
        path: 'payments',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Payments')
        },

        children: [
            {
                path: 'paid',
                name: 'payments.paid',
                component: Paid,

                meta: {
                    label: i18n.t('Paid')
                }
            }
        ]
    }
];
