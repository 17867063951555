import axios from 'axios';
import {API_URL} from 'obiado/config';
import i18n from 'obiado/lib/i18n';

const store = {
    namespaced: true,

    actions: {
        async getStatistics({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/statistics`);

            if (!res.data || !res.data.statistics) {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTodayPresencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/todayPresencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTodayAbsencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/todayAbsencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTomorrowPresencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/tomorrowPresencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getTomorrowAbsencesList({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/tomorrowAbsencesList`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        },

        async getMealsToReleasePerDietCounts({commit}) {
            const res = await axios.get(`${API_URL}/dashboard/mealsToReleasePerDietCounts`);

            if (!res.data || typeof res.data !== 'object') {
                throw new Error(i18n.t('Error occured while processing your request.'));
            }

            return res.data;
        }
    }
};

export default store;
