import i18n from 'obiado/lib/i18n';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const MealsMenus = () => import('./views/MealsMenus');
const ParentMealsMenus = () => import('./views/ParentMealsMenus');
const MealsMenuEdit = () => import('./views/MealsMenuEdit');

export default [
    {
        path: '/mealsMenus',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Meals menus')
        },

        children: [
            {
                path: '',
                name: 'mealsMenus.list',
                component: MealsMenus,

                meta: {
                    label: i18n.t('Meals menus list')
                }
            },

            {
                path: 'create',
                name: 'mealsMenus.create',
                component: MealsMenuEdit,

                meta: {
                    label: i18n.t('New meals menu')
                }
            },

            {
                path: ':mealsMenuId/edit',
                name: 'mealsMenus.edit',
                component: MealsMenuEdit,

                meta: {
                    label: i18n.t('Edit meals menu')
                }
            }
        ]
    },

    {
        path: '/meals',
        component: EmptyContainer,

        meta: {
            label: i18n.t('Meals menus')
        },

        children: [
            {
                path: '',
                name: 'mealsMenus.parentList',
                component: ParentMealsMenus,

                meta: {
                    label: i18n.t('Meals menus list')
                }
            }
        ]
    }
];
