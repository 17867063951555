import i18n from 'obiado/lib/i18n';
import store from 'obiado/lib/store';

const EmptyContainer = () => import('obiado/components/containers/EmptyContainer');
const SettingsContainer = () => import('./components/SettingsContainer');
const PasswordSettings = () => import('./views/PasswordSettings');
const PhoneNumbersSettings = () => import('./views/PhoneNumbersSettings');

const TenantPresencesSettings = () => import('./views/TenantPresencesSettings');

const routes = [
    {
        path: '/settings',
        name: 'settings',
        component: EmptyContainer,
        meta: {
            label: i18n.t('Settings')
        },
        children: [
            {
                path: 'account',
                name: 'settings.account',
                component: SettingsContainer,

                redirect: to => {
                    if (store.getters['auth/isParent']) {
                        return {name: 'settings.account.phoneNumbers'};
                    } else {
                        return {name: 'settings.account.password'};
                    }
                },

                props: {
                    title: i18n.t('Account settings'),
                    icon: 'fa fa-user'
                },

                meta: {
                    label: i18n.t('Account settings')
                },

                children: [
                    {
                        path: 'profile',
                        name: 'settings.account.phoneNumbers',
                        component: PhoneNumbersSettings
                    },

                    {
                        path: 'password',
                        name: 'settings.account.password',
                        component: PasswordSettings
                    }
                ]
            },

            {
                path: 'tenant',
                name: 'settings.tenant',
                component: SettingsContainer,

                redirect: () => {return {name: 'settings.tenant.presences'}},

                props: {
                    title: i18n.t('Tenant settings'),
                    icon: 'fa fa-building'
                },

                meta: {
                    label: i18n.t('Tenant settings')
                },

                children: [
                    {
                        path: 'presences',
                        name: 'settings.tenant.presences',
                        component: TenantPresencesSettings
                    }
                ]
            }
        ]
    }
];

export default routes;
