import axios from 'axios';
import download from 'downloadjs';
import {API_URL} from 'obiado/config';

const store = {
    namespaced: true,

    actions: {
        async presences({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/presences`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_obecnosci_' + reportData.dateFrom + '_' + reportData.dateTo + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async dailyMeals({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/dailyMeals`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_posilkow_' + reportData.date + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async periodMeals({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/periodMeals`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_posilkow_' + reportData.dateFrom + '_' + reportData.dateTo + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async mealsSummary({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/mealsSummary`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_podsumowanie_posilkow_' + reportData.dateFrom + '_' + reportData.dateTo + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async parentsBalances({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/parentsBalances`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_salda_rodzicow_' + reportData.date + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async parentsNegativeBalances({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/parentsNegativeBalances`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_ujemne_salda_rodzicow_' + reportData.date + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async payments({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/payments`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'raport_wplat_' + reportData.dateFrom + '_' + reportData.dateTo + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async presencesList({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/presencesList`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'lista_obecnosci_' + reportData.date + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async absencesList({commit}, reportData) {
            const res = await axios({
                url: `${API_URL}/reports/absencesList`,
                method: 'POST',
                responseType: 'blob',
                data: reportData
            });

            download(res.data, 'lista_nieobecnosci_' + reportData.date + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async exportChildren({commit}) {
            const res = await axios({
                url: `${API_URL}/reports/exportChildren`,
                method: 'POST',
                responseType: 'blob'
            });

            download(res.data, 'eksport_dzieci.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },

        async exportParents({commit}) {
            const res = await axios({
                url: `${API_URL}/reports/exportParents`,
                method: 'POST',
                responseType: 'blob'
            });

            download(res.data, 'eksport_rodzicow.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
    }
};

export default store;
